@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital@0;1&display=swap');

#contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    font-family: 'Source Code Pro';
}

.contact-headers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50%;
}

h2 {
    justify-content: center;
    font-size: 2rem;
}

h3 {
    color: var(--color-secondary);
    font-weight: 500;
}

.contact-container {
    display: flex;
    width: 100%;
}

.cta-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width:100%;
    font-family: 'Source Code Pro';
}

input, textarea {
    width: 50%;
    margin: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid #c1c1c0;
    resize: none;
    color: #49fc03;
    padding: 1%;
    font-size: 1rem;
    font-family: 'Source Code Pro';
}

::placeholder{
    text-align: center;
    color: #c1c1c0;
}

.contact-socials>a {
    text-decoration: none;
    color: #49fc03;
    transition: var(--transition);
}

.contact-socials>a:hover {
    color: #c1c1c0;
    transition: var(--transition);
}

.contact-socials {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin-top: 2rem;
}

@media screen and (max-width: 1024px) {
    .btn {
        font-size: 1rem;
    }
}

@media screen and (max-width: 600px) {

    h2 {
        justify-content: center;
        font-size: 1.3rem;
    }
    
    h3 {
        color: var(--color-secondary);
        font-weight: 500;
        font-size: 1rem;
    }

    .btn {
        font-size: 0.5rem;
    }

    input, textarea {
        width: 80%;
        font-size: 0.75rem;
    }
}