@import 'normalize.css';

@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

@font-face {
    font-family: 'Source Code Pro';
    src: url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital@0;1&display=swap');
}

:root {
    --color-bg: #131313;
    --color-bg-alt: #252222;
    --color-primary:#49fc03;
    --color-secondary:#c1c1c0;
    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
    --blinker: blinker 1s linear infinite;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

h1,h2 {
    color: var(--color-primary);
    font-family: 'Source Code Pro';
    font-weight: 500;
}

body {
    background-color: #131313;
    background-image: url(./assets//images/brilliant.png);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.btn {
    width: 40%;
    text-align: center;
    display: inline-block;
    color: var(--color-primary);
    padding: .75rem 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    font-family:'Source Code Pro', monospace;
    background: none;
    text-decoration: none;
    margin: 1em;
}

.btn:hover {
    background:var(--color-secondary);
    color: var(--color-bg);
    border-color: transparent;
    border-radius: 2rem;
    font-weight: 700;
}

@keyframes blinker {  
    50% { opacity: 0; }
}

h1,h2,h3,h4,h5 {
    font-weight: 500;
}

