@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

.home-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.intro>h2 {
    color: var(--color-secondary);
    font-size: 1rem;
}

.intro>h1 {
    font-family:"Orbitron";
    font-size: 4rem;
}

.blink {
    animation: var(--blinker);
    color: var(--color-primary);
    font-size: 1.5rem;
}

.static {
    color: var(--color-primary);
    font-size: 1.5rem;
}

p {
    text-align: center;
    font-family: 'Source Code Pro';
    color: var(--color-secondary);
    margin-top: 10%;
    align-items: flex-end;
}

@media screen and (max-width: 1024px) {
    .intro>h1 {
        font-size: 2.3rem;
    }
}

@media screen and (max-width: 600px) {
    .intro>h1 {
        font-size: 1.5rem;
    }
}